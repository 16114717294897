import PropTypes from "prop-types"

import ServiceCard from "shared/components/service_card"
import { serviceShape } from "app/lib/shared_prop_types"
import { onClickSearchResult } from "../get_more/get_more_landing_page"

const SearchServiceCard = ({ service, path }) => {
  return (
    <li className="service-card-list-item">
      <ServiceCard
        service={service}
        path={path}
        onClick={() =>
          onClickSearchResult("explore_click_service_search_result", {
            searchResultType: "service",
            searchResultId: service.module_name,
          })
        }
      />
    </li>
  )
}

SearchServiceCard.propTypes = {
  location: PropTypes.string.isRequired,
  path: PropTypes.string.isRequired,
  position: PropTypes.number.isRequired,
  service: serviceShape.isRequired,
  term: PropTypes.string,
}

SearchServiceCard.defaultProps = {
  service: {},
}

export default SearchServiceCard
