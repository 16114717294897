import { Component } from "react"
import PropTypes from "prop-types"

import ServiceCard from "shared/components/service_card"
import { serviceShape } from "app/lib/shared_prop_types"
import { onClickSearchResult } from "../get_more_landing_page"

class GetMoreServiceCard extends Component {
  render() {
    return (
      <div className="explore-tile">
        <li
          ref={el => {
            this.scrollObserverEl = el
          }}
          className="service-card-list-item"
        >
          <ServiceCard
            service={this.props.service}
            path={`/${this.props.service.module_name}`}
            onClick={() =>
              onClickSearchResult("explore_click_get_more_service", {
                searchResultType: "service",
                searchResultId: this.props.service.module_name,
              })
            }
          />
        </li>
      </div>
    )
  }
}

GetMoreServiceCard.propTypes = {
  service: serviceShape.isRequired,
  position: PropTypes.number.isRequired,
}

GetMoreServiceCard.defaultProps = {
  service: {},
}

export default GetMoreServiceCard
